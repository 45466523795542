<template>
  <div
    class="SlaConfigField"
  >
    <div class="SlaConfigField__label">
      {{ label }}
    </div>
    <div
      class="SlaConfigField__input"
      @click.capture="turnedOn = true; $nextTick(() => $refs.textField.focus())"
    >
      <AppTextField
        ref="textField"
        :value="textFieldModel"
        filled
        hide-details
        margins-with-hidden-details="mb-0"
        type="number"
        min="1"
        label=" "
        :readonly="!turnedOn"
        :placeholder="placeholder"
        persistent-placeholder
        @change="textFieldModel = $event.trim()"
        @blur="emitModelUpdate"
      />
    </div>

    <div class="SlaConfigField__actions">
      <v-tooltip
        bottom
        open-delay="300"
      >
        <template #activator="{ on, attrs }">
          <v-btn
            text
            plain
            v-bind="attrs"
            :disabled="!canBeReset"
            @click="reset"
            v-on="on"
          >
            <v-icon
              color="primary"
              class="mr-1"
              v-text="'mdi-refresh'"
            />
            {{ $t('sla.Reset') }}
          </v-btn>
        </template>
        <span>
          {{ $t('sla.ResetToDefault') }}
        </span>
      </v-tooltip>
      <div
        style="width: 61px"
        class="ml-4"
      >
        <AppSwitch
          :input-value="turnedOn"
          :label="turnedOn ? $t('sla.on') : $t('sla.off')"
          hide-details
          :reversed="false"
          class="my-0 py-0"
          @change="
            turnedOn = $event;
            $event ? $nextTick(() => $refs.textField.focus()) : $nextTick(emitModelUpdate)
          "
        />
      </div>
    </div>
  </div>
</template>

<script>
const initTextFieldModel = value =>
  value == null || value <= 0
    ? ''
    : String(value)
const initTurnedOn = (value, defaultValue) =>
  value == null
    ? defaultValue != null && defaultValue > 0
    : value > 0

export default {
  name: 'SlaConfigField',

  props: {
    label: { type: String, default: '' },
    value: { type: Number, default: null },
    defaultValue: { type: Number, required: true },
  },

  data() {
    return {
      textFieldModel: initTextFieldModel(this.value),
      turnedOn: initTurnedOn(this.value, this.defaultValue),
    }
  },

  computed: {
    computedNewValue() {
      const { textFieldModel, turnedOn, defaultValue } = this
      if (!turnedOn) return -1
      if (!textFieldModel) return defaultValue
      return Number(textFieldModel)
    },

    canBeReset() {
      return this.value != null
    },

    placeholder() {
      const { defaultValue } = this
      if (defaultValue == null) {
        console.warn('Default value cannot be null')
        return ''
      }
      if (defaultValue <= 0) return this.$t('sla.None')
      return String(defaultValue)
    },
  },

  watch: {
    value(value) {
      const textFieldModel = initTextFieldModel(value)
      const turnedOn = initTurnedOn(value, this.defaultValue)
      if (textFieldModel !== this.textFieldModel) this.textFieldModel = textFieldModel
      if (turnedOn !== this.turnedOn) this.turnedOn = turnedOn
    },
  },

  methods: {
    reset() {
      this.textFieldModel = initTextFieldModel(null)
      this.turnedOn = initTurnedOn(null, this.defaultValue)
      this.$emit('input', null)
    },

    emitModelUpdate() {
      this.turnedOn = initTurnedOn(this.computedNewValue, this.defaultValue)
      this.$emit('input', this.computedNewValue)
    },
  },
}
</script>

<style lang="sass">
.SlaConfigField
  display: flex
  align-items: center

  &__input
    margin-right: 26px !important

  &__label
    flex: 0 0 116px
    height: 56px
    padding-left: 10px
    padding-top: 25px
    margin-right: 16px
    line-height: 21px
    letter-spacing: 0.005em

  &__actions
    flex-shrink: 0
    display: flex
    align-items: center
</style>
