<template>
  <div class="SlaConfig">
    <AppBarLayout>
      <template #header>
        {{ $t('sla.Sla') }}
      </template>
    </AppBarLayout>

    <div class="SlaConfig__page-content">
      <div v-if="slaConfig != null">
        <div class="SlaConfig__headers">
          <div class="SlaConfig__header">
            {{ $t('sla.IssueScore') }}
          </div>
          <div class="SlaConfig__header">
            {{ $t('sla.DaysToRemediate') }}
          </div>
        </div>

        <SlaConfigField
          v-for="field in FIELDS"
          :key="field.name"
          v-model="model[field.name]"
          :label="field.label"
          :default-value="slaConfig.default[field.name]"
          class="SlaConfig__field"
        />
      </div>

      <!--pre v-text="{ slaConfig, model, recalculate }" /-->
    </div>

    <div class="SlaConfig__footer">
      <v-radio-group
        v-model="recalculate"
        hide-details
        class="SlaConfig__radio-group"
      >
        <v-radio
          :value="true"
          class="SlaConfig__radio"
        >
          <template #label>
            <span
              v-text="projectId == null
                ? $t('sla.RecalculateAllM')
                : $t('sla.RecalculateThisM')"
            />
            <v-tooltip top>
              <template #activator="{ on, attrs }">
                <v-icon
                  class="ml-2"
                  v-bind="attrs"
                  v-on="on"
                  v-text="'mdi-help-box-outline'"
                />
              </template>

              <i18n
                path="sla.ExceptManuallyChangedAndFixConfirmedM"
                tag="span"
              >
                <br>
              </i18n>
            </v-tooltip>
          </template>
        </v-radio>
        <v-radio
          :label="$t('sla.ApplyForFutureOnlyM')"
          :value="false"
          class="SlaConfig__radio"
        />
      </v-radio-group>

      <v-btn
        class="ml-auto"
        color="primary"
        depressed
        :disabled="requestPending || !slaConfig"
        :loading="saving"
        @click="applyChanges"
      >
        {{ $t('sla.Save') }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import { PROJECT_PERMISSION_LEVEL as PERM } from '../constants'
import { replaceRoute } from '../helpers'

import Project from '../store/orm/project'
import SlaConfig from '../store/orm/slaConfig'

import AppBarLayout from '../layouts/AppBarLayout'
import SlaConfigField from './SlaConfigField'
import i18n from '../i18n'

const FIELDS = Object.freeze([
  { name: 'SLADaysCritical', label: i18n.t('sla.Critical') },
  { name: 'SLADaysHigh', label: i18n.t('sla.High') },
  { name: 'SLADaysMedium', label: i18n.t('sla.Medium') },
  { name: 'SLADaysLow', label: i18n.t('sla.Low') },
  { name: 'SLADaysInfo', label: i18n.t('sla.Info') },
  { name: 'SLADaysDefault', label: i18n.t('sla.Undefined') },
])

const initModel = (config = null) => FIELDS.reduce((model, field) => {
  model[field.name] = config == null
    ? null
    : config.actual[field.name]
  return model
}, {})

export default {
  name: 'SlaConfig',

  components: {
    AppBarLayout,
    SlaConfigField,
  },

  props: {
    projectId: { type: String, default: null },
  },

  metaInfo() {
    return {
      title: this.$store.getters.title(this.$t('sla.ServiceLevelAgreement')),
    }
  },

  data() {
    return {
      FIELDS,
      fetching: false,
      saving: false,
      model: initModel(),
      recalculate: true,
    }
  },

  computed: {
    currentUser() { return this.$store.getters['user/current'] },
    isAdmin() { return this.currentUser?.isAdmin },

    project() { return this.projectId && Project.find(this.projectId) },
    projectPerm() { return this.project?.permission },

    canEdit() {
      const { isAdmin, projectId, projectPerm } = this
      if (projectId) {
        if (isAdmin) return true
        if (!projectPerm) return null
        return projectPerm === PERM.OWNER.value
      } else {
        return isAdmin
      }
    },

    requestPending() { return this.fetching || this.saving },

    slaConfig() {
      const { projectId } = this

      return projectId == null
        ? SlaConfig.getGlobal()
        : SlaConfig.query().withAll().find(projectId)
    },
  },

  watch: {
    projectId: {
      handler(projectId) {
        this.fetchSlaConfig()
        if (projectId) this.fetchProject()
      },
      immediate: true,
    },

    slaConfig: {
      handler(slaConfig) { this.model = initModel(slaConfig) },
      immediate: true,
      deep: true,
    },

    canEdit: {
      handler(canEdit) {
        if (canEdit || canEdit == null) return
        if (this.projectId) {
          replaceRoute(this.$router, {
            name: 'Project',
            params: { projectId: this.projectId },
          })
        } else {
          replaceRoute(this.$router, '/')
        }
      },
      immediate: true,
    },
  },

  methods: {
    async fetchSlaConfig() {
      if (this.fetching) throw new Error('Request is already pending')
      const { projectId } = this
      this.fetching = true
      try {
        if (projectId == null) await SlaConfig.dispatch('$getGlobal')
        else await SlaConfig.dispatch('$getForProject', { projectId })
      } catch (e) {
        console.error(e)
      } finally {
        this.fetching = false
      }
    },

    fetchProject() {
      const { projectId } = this
      if (!projectId) throw new Error('No projectId to fetch')
      return Project.dispatch('$getOne', { projectId, reload: false })
    },

    async applyChanges() {
      if (this.saving) throw new Error('Request is already pending')
      const { projectId, model: slaSettings, recalculate: recalculateSLAForIssues } = this
      this.saving = true

      const errorSnackbarArgs = {
        action: {
          type: 'function',
          label: this.$t('sla.Recalculate'),
          // icon: 'mdi-function',
          fn: () => {
            this.recalculate = true
            this.applyChanges()
          },
          once: true,
        },
        timeout: -1,
      }

      try {
        if (projectId == null) {
          await SlaConfig.dispatch('$setGlobal', {
            slaSettings,
            recalculateSLAForIssues,
            errorSnackbarArgs,
          })
        } else {
          await SlaConfig.dispatch('$setForProject', {
            projectId,
            slaSettings,
            recalculateSLAForIssues,
            errorSnackbarArgs,
          })
        }
      } catch (e) {
        console.error(e)
      } finally {
        this.saving = false
      }
    },
  },
}
</script>

<style lang="sass">
@import '../scss/variables'

.SlaConfig
  background: white
  height: calc(100vh - 56px)

  @media #{map-get($display-breakpoints, 'md-and-up')}
    height: calc(100vh - 64px)

  &__page-content
    padding: 32px 84px
    height: calc(100% - 76px)
    overflow: auto

  &__headers
    display: flex
    margin-bottom: 36px

  &__header
    font-size: 14px
    line-height: 20px
    letter-spacing: 0.005em
    color: #A09EB9 // Text/Secondary

    &:first-child
      flex: 0 0 129px

  &__field
    max-width: 428px
    margin-bottom: 36px

  &__footer
    height: 76px
    width: 100%
    padding: 18px 32px
    display: flex
    align-items: center

    background: #F5F5F9 // Neutral/97
    border-top: 1px solid #E6E6F2

  &__radio-group
    margin: 0 !important
    padding: 0 !important
    .v-input--radio-group__input
      flex-direction: row

  &__radio
    margin: 0 !important

    & + &
      margin-left: 14px !important
</style>
